import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useLazyCheckDevicesWithAbnormalPowerConsumptionQuery,
  useLazyGetSpaceQuery,
  useLazyGetSpacesQuery,
} from "../../../redux/api/space/spaceAPI";
import {
  useLazyGetControllerStatisticsQuery,
  useLazyGetEnergyConsumptionStatisticsByDateQuery,
  useLazyGetGraphQuery,
  useLazyGetPowerUsageStatisticsByYearMonthQuery,
  useLazyViewDevicesWithHighestPowerConsumptionQuery,
  useLazyViewEnergyConsumptionByDateQuery,
  useLazyViewExternalConsumptionDataPercentagesQuery,
  useLazyViewPredictedPowerUsageForCurrentDayQuery,
} from "../../../redux/api/usage/usageAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  defaultSmartDevice,
  selectUsageFilter,
  setSelectedEntireSpace,
  setUsageFilter,
} from "../../../redux/features/usage-filter/usage-filter-slice";
import HighestPowerUsageDevices from "../../../shared/components/height-power-usage-devices/highest-power-usage-devices";
import RealTimePowerUsageChartView from "../../../shared/components/real-time-power-usage-chart-view/real-time-power-usage-chart-view";
import StaticInfoCard from "../../../shared/components/static-info-card/static-info-card";
import ControllerGraphRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/controller-graph-request-dto";
import ControllerStatisticsRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/controller-statistics-request-dto";
import EnergyConsumptionStatisticsByDateRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/energy-consumption-statistics-by-date-request-dto";
import PowerUsageStatisticsByYearMonthRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/power-usage-statistics-by-year-month-request-dto";
import { IViewDevicesWithHighestPowerConsumptionRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/view-devices-with-highest-power-consumption-request-dto";
import { IViewSmartPlugEnergyConsumptionByDateRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/view-smart-plug-energy-consumption-by-date-request-dto";
import CheckDevicesWithAbnormalPowerConsumptionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/check-devices-with-abnormal-power-consumption-response-dto";
import ControllerStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/controller-statistics-response-dto";
import EnergyConsumptionStatisticsByDateResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/energy-consumption-statistics-by-date-response-dto";
import PowerUsageStatisticsByYearMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/power-usage-statistics-by-year-month-response-dto";
import { IViewDevicesWithHighestPowerConsumptionPowerConsumerView } from "../../../shared/oversight-core/dtos/response-dtos/view-devices-with-highest-power-consumption-response-dto";
import { IExternalConsumptionPercentage } from "../../../shared/oversight-core/dtos/response-dtos/view-external-consumption-data-percentages-response-dto";
import ViewPowerUsageStatsBySpaceClusterResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-power-usage-stats-by-space-cluster-response-dto";
import { EConnectionStatus } from "../../../shared/oversight-core/enums/connection-status";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import { EDemandPeriod } from "../../../shared/oversight-core/enums/demand-period";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../shared/oversight-core/enums/smart-controller-type";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../../shared/oversight-core/interfaces/selected-space";
import ISmartController from "../../../shared/oversight-core/interfaces/smart-controller";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import AppBannerWithIcon from "../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { convertToTimeZone } from "../../../shared/oversight-core/utils/time-utils";
import AbnormalEnergyUsageModal from "../space-clusters/components/models/abnormal-energy-usage-modal/abnormal-energy-usage-modal";
import UsageByTime from "./components/usage-by-time/usage-by-time";

const defaultStatistics: ControllerStatisticsResponseDTO = {
  latestPowerInWatt: 0,
  maxPowerInWatt: 0,
  averagePowerInWatt: 0,
  usageDataFrom: EUsageDataFrom.PLC,
};

const defaultPowerUsage: PowerUsageStatisticsByYearMonthResponseDTO = {
  maxPowerInWatt: 0,
  averagePowerInWatt: 0,
  usageDataFrom: "",
};

const defaultEnergyConsumption: EnergyConsumptionStatisticsByDateResponseDTO = {
  consumedEnergyInUnits: 0,
  dailyAverageConsumedEnergyInUnits: 0,
  monthlyConsumedEnergyInUnits: 0,
  monthlyAverageConsumedEnergyInUnits: 0,
  usageDataFrom: "",
};

const defaultGraph = [0, 0, 0, 0, 0];
const defaultLabels = ["", "", "", "", ""];

const Usage = () => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [
    isDevicesWithHighestPowerConsumptionFirstTime,
    setIsDevicesWithHighestPowerConsumptionFirstTime,
  ] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const usageFilterStore = useSelector(selectUsageFilter);
  const [graphData, setGraphData] = useState<number[]>([...defaultGraph]);
  const [labels, setLabels] = useState<string[]>([...defaultLabels]);
  const [statistics, setStatistics] = useState<ControllerStatisticsResponseDTO>(
    { ...defaultStatistics }
  );
  const [powerUsage, setPowerUsage] =
    useState<PowerUsageStatisticsByYearMonthResponseDTO>({
      ...defaultPowerUsage,
    });
  const [energyConsumption, setEnergyConsumption] =
    useState<EnergyConsumptionStatisticsByDateResponseDTO>({
      ...defaultEnergyConsumption,
    });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [
    devicesWithHighestPowerConsumption,
    setDevicesWithHighestPowerConsumption,
  ] = useState<IViewDevicesWithHighestPowerConsumptionPowerConsumerView[]>([]);
  const [predictedPowerUsageUnits, setPredictedPowerUsageUnits] =
    useState("Loading");
  const [collapsed, setCollapsed] = useState(true);
  const [showAbnormalEnergyUsageModal, setShowAbnormalEnergyUsageModal] =
    useState(false);
  const [isAbnormalPowerConsumers, setIsAbnormalPowerConsumers] =
    useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
    setUpdateCurrentStateDevicesWithAbnormalPowerConsumption,
  ] = useState(false);
  const [
    externalConsumptionDataPercentages,
    setExternalConsumptionDataPercentages,
  ] = useState<IExternalConsumptionPercentage[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isEnergyConsumptionGraph, setIsEnergyConsumptionGraph] =
    useState(false);
  const [energyConsumptionGraphData, setEnergyConsumptionGraphData] = useState<
    number[]
  >([]);
  const [energyConsumptionGraphLabels, setEnergyConsumptionGraphLabels] =
    useState<string[]>([...defaultLabels]);

  const onFilter = (
    selectedSpace: ISelectedSpace,
    selectedDeviceType: string,
    selectedDevice: { id: string; name: string },
    selectedSmartDevice: ISmartController,
    isApplyForChildSpaces: boolean,
    selectedEntireSpace: ISpaceView | undefined,
    from: string,
    to: string,
    viewType: EDateTypes
  ) => {
    dispatch(
      setUsageFilter({
        selectedSpace,
        selectedDeviceType: selectedDeviceType,
        selectedDevice: {
          id: selectedDevice?.id || "",
          name: selectedDevice?.name || "",
        },
        selectedSmartDevice: selectedSmartDevice,
        isApplyForChildSpaces: isApplyForChildSpaces,
        selectedEntireSpace,
        from,
        to,
        viewType,
      })
    );
  };

  const [triggerGetControllerGraph, { isFetching: isFetchingControllerGraph }] =
    useLazyGetGraphQuery();
  const [
    triggerGetControllerStatistics,
    { isFetching: isFetchingControllerStatistics },
  ] = useLazyGetControllerStatisticsQuery();
  const [
    triggerGetPowerUsageStatisticsByYearMonth,
    { isFetching: isFetchingPowerUsageStatisticsByYearMonth },
  ] = useLazyGetPowerUsageStatisticsByYearMonthQuery();
  const [
    triggerGetEnergyConsumptionStatisticsByDate,
    { isFetching: isFetchingEnergyConsumptionStatisticsByDate },
  ] = useLazyGetEnergyConsumptionStatisticsByDateQuery();
  const [
    triggerViewDevicesWithHighestPowerConsumption,
    { isFetching: isFetchingDevicesWithHighestPowerConsumption },
  ] = useLazyViewDevicesWithHighestPowerConsumptionQuery();
  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceCluster }] =
    useLazyGetSpacesQuery();
  const [triggerViewPredictedPowerUsageForCurrentDay] =
    useLazyViewPredictedPowerUsageForCurrentDayQuery();
  const [triggerCheckDevicesWithAbnormalPowerConsumption] =
    useLazyCheckDevicesWithAbnormalPowerConsumptionQuery();
  const [triggerGetSpace] = useLazyGetSpaceQuery();
  const [
    triggerViewExternalConsumptionDataPercentagesData,
    { isFetching: isFetchingViewExternalConsumptionDataPercentages },
  ] = useLazyViewExternalConsumptionDataPercentagesQuery();
  const [
    triggerEnergyConsumptionByDate,
    { isFetching: isFetchingEnergyConsumptionByDate },
  ] = useLazyViewEnergyConsumptionByDateQuery();

  const checkDeviceConnectionState = (
    space: ISpaceView | undefined
  ): boolean => {
    if (space === undefined) {
      return false;
    }

    const smartControllerWithSpaceCluster = space.smartDevices.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED &&
        smartDevice.smartDeviceType === ESmartControllerType.SMART_PLUG
    );

    if (
      smartControllerWithSpaceCluster ||
      !usageFilterStore.isApplyForChildSpaces
    ) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  useEffect(() => {
    if (
      billingSpaceFilter &&
      billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber
    ) {
      triggerViewExternalConsumptionDataPercentagesData({
        year: moment(new Date()).year(),
        month: moment(new Date()).month() + 1,
        accountNumber:
          billingSpaceFilter.spaceCluster.serviceProviderAccount.accountNumber,
      })
        .unwrap()
        .then((res) => {
          setExternalConsumptionDataPercentages(res.percentages);
        })
        .catch(() => {
          setExternalConsumptionDataPercentages([]);
        });
    }
  }, [billingSpaceFilter]);

  useEffect(() => {
    if (
      usageFilterStore.selectedSpace.clusterId &&
      usageFilterStore.selectedSpace.id
    ) {
      triggerGetSpace({
        clusterId: usageFilterStore.selectedSpace.clusterId,
        spaceId: usageFilterStore.selectedSpace.id,
      })
        .unwrap()
        .then((res) => {
          dispatch(
            setSelectedEntireSpace({
              selectedEntireSpace: res.subSpaceCluster.rootSpace,
            })
          );
        })
        .catch(() => {
          setSelectedEntireSpace({
            selectedEntireSpace: undefined,
          });
        });
    }
  }, [usageFilterStore.selectedSpace]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        usageFilterStore.selectedSpace.clusterId &&
        usageFilterStore.selectedSpace.id
      ) {
        triggerGetSpace({
          clusterId: usageFilterStore.selectedSpace.clusterId,
          spaceId: usageFilterStore.selectedSpace.id,
        })
          .unwrap()
          .then((res) => {
            dispatch(
              setSelectedEntireSpace({
                selectedEntireSpace: res.subSpaceCluster.rootSpace,
              })
            );
          })
          .catch(() => {
            setSelectedEntireSpace({
              selectedEntireSpace: undefined,
            });
          });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [usageFilterStore.selectedSpace]);

  useEffect(() => {
    triggerCheckDevicesWithAbnormalPowerConsumption({
      spaceClusterId: billingSpaceFilter.spaceCluster.id,
      spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
    })
      .unwrap()
      .then((res: CheckDevicesWithAbnormalPowerConsumptionResponseDTO) => {
        setIsAbnormalPowerConsumers(res.abnormalPowerConsumersDetected);
        if (!isAbnormalPowerConsumers) {
          setShowAbnormalEnergyUsageModal(false);
        }
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    triggerCheckDevicesWithAbnormalPowerConsumption,
    billingSpaceFilter.spaceCluster,
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
  ]);

  useEffect(() => {
    triggerViewPredictedPowerUsageForCurrentDay({
      spaceClusterId: usageFilterStore.selectedSpace.clusterId,
      zoneOffset: offsetToString(moment().utcOffset()),
    })
      .unwrap()
      .then((res: ViewPowerUsageStatsBySpaceClusterResponseDTO) => {
        setPredictedPowerUsageUnits(
          `${res.dailyAverageUnitsPrediction.toFixed(2)} Units`
        );
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONS_0033
        ) {
          setPredictedPowerUsageUnits("Learning");
          return;
        }
        setPredictedPowerUsageUnits("Error");
      });
  }, [
    triggerViewPredictedPowerUsageForCurrentDay,
    usageFilterStore.selectedSpace.clusterId,
  ]);

  useEffect(() => {
    const triggerViewDevicesWithHighestPowerConsumptionObject: IViewDevicesWithHighestPowerConsumptionRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        from: moment().toISOString(),
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerViewDevicesWithHighestPowerConsumptionObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerViewDevicesWithHighestPowerConsumptionObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    if (collapsed) {
      setDevicesWithHighestPowerConsumption([]);
      setIsDevicesWithHighestPowerConsumptionFirstTime(true);
    }

    if (usageFilterStore.selectedSpace.clusterId && !collapsed) {
      triggerViewDevicesWithHighestPowerConsumption(
        triggerViewDevicesWithHighestPowerConsumptionObject
      )
        .unwrap()
        .then((res) => {
          setDevicesWithHighestPowerConsumption(res.powerConsumerViews);
        })
        .catch(() => {
          setDevicesWithHighestPowerConsumption([]);
        });
    }
  }, [collapsed, usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerViewDevicesWithHighestPowerConsumptionObject: IViewDevicesWithHighestPowerConsumptionRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        from: moment().toISOString(),
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerViewDevicesWithHighestPowerConsumptionObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerViewDevicesWithHighestPowerConsumptionObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    const interval = setInterval(() => {
      if (collapsed) {
        setDevicesWithHighestPowerConsumption([]);
      }

      if (usageFilterStore.selectedSpace.clusterId && !collapsed) {
        setIsDevicesWithHighestPowerConsumptionFirstTime(false);
        triggerViewDevicesWithHighestPowerConsumption({
          ...triggerViewDevicesWithHighestPowerConsumptionObject,
          from: moment().toISOString(),
        })
          .unwrap()
          .then((res) => {
            setDevicesWithHighestPowerConsumption(res.powerConsumerViews);
          })
          .catch(() => {
            setDevicesWithHighestPowerConsumption([]);
          });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [collapsed, usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    if (isFirstTime) {
      ReactGA.event({
        category: "Click",
        action: "Usage Menu Item Click",
      });
    }
  }, [isFirstTime]);

  useEffect(() => {
    setIsFirstTime(true);
    const triggerGetControllerGraphObject: ControllerGraphRequestDTO = {
      spaceClusterId: usageFilterStore.selectedSpace.clusterId,
      from: moment().toISOString(),
    };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetControllerGraphObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetControllerGraphObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetControllerGraphObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetControllerGraphObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    if (usageFilterStore.selectedSpace.clusterId) {
      triggerGetControllerGraph(triggerGetControllerGraphObject)
        .unwrap()
        .then((res) => {
          const data = res.powerUsage.map((dataItem) =>
            parseFloat(dataItem.powerInWatt.toFixed(2))
          );
          const timeStamps = res.powerUsage.map((dataItem) => {
            const timestamp = convertToTimeZone(dataItem.timeStamp, "+05:30");
            const time = timestamp.substring(11, 19);

            return time;
          });

          setGraphData(data);
          setLabels(timeStamps);
          setIsFirstTime(false);
        })
        .catch(() => {
          setGraphData([...defaultGraph]);
          setLabels([...defaultLabels]);
        });
    }
  }, [usageFilterStore]);

  useEffect(() => {
    const triggerGetControllerGraphObject: ControllerGraphRequestDTO = {
      spaceClusterId: usageFilterStore.selectedSpace.clusterId,
      from: moment().toISOString(),
    };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetControllerGraphObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetControllerGraphObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetControllerGraphObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetControllerGraphObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    const interval = setInterval(() => {
      if (usageFilterStore.selectedSpace.clusterId) {
        triggerGetControllerGraphObject.from = moment().toISOString();
        triggerGetControllerGraph({
          ...triggerGetControllerGraphObject,
          from: moment().toISOString(),
        })
          .unwrap()
          .then((res) => {
            const data = res.powerUsage.map((dataItem) =>
              parseFloat(dataItem.powerInWatt.toFixed(2))
            );
            const timeStamps = res.powerUsage.map((dataItem) => {
              const timestamp = convertToTimeZone(dataItem.timeStamp, "+05:30");
              const time = timestamp.substring(11, 19);

              return time;
            });

            setGraphData(data);
            setLabels(timeStamps);
            setIsFirstTime(false);
          })
          .catch(() => {
            setGraphData([...defaultGraph]);
            setLabels([...defaultLabels]);
          });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [usageFilterStore]);

  useEffect(() => {
    const triggerGetControllerStatisticsObject: ControllerStatisticsRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        date: moment().toISOString(),
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetControllerStatisticsObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetControllerStatisticsObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetControllerStatisticsObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetControllerStatisticsObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    if (usageFilterStore.selectedSpace.clusterId) {
      triggerGetControllerStatistics(triggerGetControllerStatisticsObject)
        .unwrap()
        .then((res) => {
          setStatistics(res);
        })
        .catch(() => {
          setStatistics({ ...defaultStatistics });
        });
    }
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerGetControllerStatisticsObject: ControllerStatisticsRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        date: moment().toISOString(),
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetControllerStatisticsObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetControllerStatisticsObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetControllerStatisticsObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetControllerStatisticsObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    const interval = setInterval(() => {
      if (usageFilterStore.selectedSpace.clusterId) {
        triggerGetControllerStatistics(triggerGetControllerStatisticsObject)
          .unwrap()
          .then((res) => {
            setStatistics(res);
            setIsFirstTime(false);
          })
          .catch(() => {
            setStatistics({ ...defaultStatistics });
          });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerGetPowerUsageStatisticsByYearMonthObject: PowerUsageStatisticsByYearMonthRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        year: moment().year(),
        month: moment().month() + 1,
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetPowerUsageStatisticsByYearMonthObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetPowerUsageStatisticsByYearMonthObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetPowerUsageStatisticsByYearMonthObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetPowerUsageStatisticsByYearMonthObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    if (usageFilterStore.selectedSpace.clusterId) {
      triggerGetPowerUsageStatisticsByYearMonth(
        triggerGetPowerUsageStatisticsByYearMonthObject
      )
        .unwrap()
        .then((res) => {
          setPowerUsage(res);
        })
        .catch(() => {
          setPowerUsage({ ...defaultPowerUsage });
        });
    }
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerGetPowerUsageStatisticsByYearMonthObject: PowerUsageStatisticsByYearMonthRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        year: moment().year(),
        month: moment().month() + 1,
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetPowerUsageStatisticsByYearMonthObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetPowerUsageStatisticsByYearMonthObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetPowerUsageStatisticsByYearMonthObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetPowerUsageStatisticsByYearMonthObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    const interval = setInterval(() => {
      if (usageFilterStore.selectedSpace.clusterId) {
        triggerGetPowerUsageStatisticsByYearMonth(
          triggerGetPowerUsageStatisticsByYearMonthObject
        )
          .unwrap()
          .then((res) => {
            setPowerUsage(res);
            setIsFirstTime(false);
          })
          .catch(() => {
            setPowerUsage({ ...defaultPowerUsage });
          });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerGetEnergyConsumptionStatisticsByDateObject: EnergyConsumptionStatisticsByDateRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        date: moment().toISOString(),
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetEnergyConsumptionStatisticsByDateObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetEnergyConsumptionStatisticsByDateObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetEnergyConsumptionStatisticsByDateObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetEnergyConsumptionStatisticsByDateObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    if (usageFilterStore.selectedSpace.clusterId) {
      triggerGetEnergyConsumptionStatisticsByDate(
        triggerGetEnergyConsumptionStatisticsByDateObject
      )
        .unwrap()
        .then((res) => {
          setEnergyConsumption(res);
        })
        .catch(() => {
          setEnergyConsumption({ ...defaultEnergyConsumption });
        });
    }
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerGetEnergyConsumptionStatisticsByDateObject: EnergyConsumptionStatisticsByDateRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        date: moment().toISOString(),
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetEnergyConsumptionStatisticsByDateObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetEnergyConsumptionStatisticsByDateObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetEnergyConsumptionStatisticsByDateObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetEnergyConsumptionStatisticsByDateObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    const interval = setInterval(() => {
      if (usageFilterStore.selectedSpace.clusterId) {
        triggerGetEnergyConsumptionStatisticsByDate(
          triggerGetEnergyConsumptionStatisticsByDateObject
        )
          .unwrap()
          .then((res) => {
            setEnergyConsumption(res);
            setIsFirstTime(false);
          })
          .catch(() => {
            setEnergyConsumption({ ...defaultEnergyConsumption });
          });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    const triggerGetEnergyConsumptionByDateObject: IViewSmartPlugEnergyConsumptionByDateRequestDTO =
      {
        spaceClusterId: usageFilterStore.selectedSpace.clusterId,
        from: usageFilterStore.from,
        to: usageFilterStore.to,
        viewType: usageFilterStore.viewType,
      };

    if (!usageFilterStore.isApplyForChildSpaces) {
      triggerGetEnergyConsumptionByDateObject.requiredDepth = 0;
    }

    if (usageFilterStore.selectedDeviceType) {
      triggerGetEnergyConsumptionByDateObject.deviceType =
        usageFilterStore.selectedDeviceType;
    }

    if (usageFilterStore.selectedDevice.id) {
      triggerGetEnergyConsumptionByDateObject.smartPlugId =
        usageFilterStore.selectedDevice.id;
    }

    if (usageFilterStore.selectedSpace.id) {
      triggerGetEnergyConsumptionByDateObject.spaceId =
        usageFilterStore.selectedSpace.id;
    }

    if (usageFilterStore.selectedSpace.clusterId) {
      triggerEnergyConsumptionByDate(triggerGetEnergyConsumptionByDateObject)
        .unwrap()
        .then((res) => {
          const data = res.energyConsumption.map((dataItem) =>
            Number(Helper.roundTo2(dataItem.consumption / 1000))
          );
          const timeStamps = res.energyConsumption.map((dataItem) => {
            const timestamp = moment(dataItem.timeStamp).utcOffset("+05:30");

            if (usageFilterStore.viewType === EDateTypes.HOURLY) {
              return timestamp.format("HH:mm");
            } else if (usageFilterStore.viewType === EDateTypes.DAILY) {
              return timestamp.format("MM-DD");
            } else if (usageFilterStore.viewType === EDateTypes.MONTHLY) {
              return timestamp.format("MM-YYYY");
            } else {
              return timestamp.format("YYYY");
            }
          });

          setEnergyConsumptionGraphData(data);
          setEnergyConsumptionGraphLabels(timeStamps);
        })
        .catch(() => {
          setEnergyConsumptionGraphData([]);
          setEnergyConsumptionGraphLabels([...defaultLabels]);
        });
    }
  }, [usageFilterStore, usageFilterStore.isApplyForChildSpaces]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster) {
      dispatch(
        setUsageFilter({
          selectedSpace: {
            id: billingSpaceFilter.spaceCluster.rootSpace.id,
            clusterId: billingSpaceFilter.spaceCluster.id,
            name: billingSpaceFilter.spaceCluster.label,
          },
          selectedDeviceType: "",
          selectedDevice: { id: "", name: "" },
          selectedSmartDevice: { ...defaultSmartDevice },
          isApplyForChildSpaces: true,
          selectedEntireSpace: billingSpaceFilter.spaceCluster.rootSpace,
          from: moment().startOf("day").toISOString(),
          to: moment().toISOString(),
          viewType: EDateTypes.HOURLY,
        })
      );
    }
  }, [billingSpaceFilter.spaceCluster]);

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          {isAbnormalPowerConsumers && showInstruction && (
            <div className="mb-3">
              <AppBannerWithIcon
                content="Abnormal Energy Usage of Turned Off Devices Detected..."
                bannerVariant="brown"
                icon="live_help"
                iconVariant="#CD9368"
                button={
                  <Row className="align-items-center g-4">
                    <Col>
                      <AppButton
                        text={"View"}
                        variant={"transparent"}
                        onClick={() => setShowAbnormalEnergyUsageModal(true)}
                        size="medium"
                        className="px-0"
                      />
                    </Col>
                    <Col>
                      <div
                        className="instruction-close bg-primary text-white cursor-pointer"
                        onClick={() => setShowInstruction(false)}
                      >
                        x
                      </div>
                    </Col>
                  </Row>
                }
              />
            </div>
          )}
          <RealTimePowerUsageChartView
            deviceName={usageFilterStore.selectedDevice.name}
            deviceType={usageFilterStore.selectedDeviceType as EDeviceTypes}
            spaceName={usageFilterStore.selectedSpace.name}
            graphData={graphData}
            statistics={statistics}
            labels={labels}
            onFilter={onFilter}
            isFetching={
              (isFetchingControllerGraph && isFirstTime) ||
              (isFetchingControllerStatistics && isFirstTime)
            }
            predictedPowerUsageUnits={predictedPowerUsageUnits}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            isEnergyConsumptionGraph={isEnergyConsumptionGraph}
            setIsEnergyConsumptionGraph={setIsEnergyConsumptionGraph}
          />
          <Row className="my-4">
            <Col className="col-12" lg={6}>
              <StaticInfoCard
                type="POWER_USAGE"
                spaceName={usageFilterStore.selectedSpace.name}
                selectedSmartDevice={usageFilterStore.selectedSmartDevice}
                date={new Date()}
                onMoreInfoClick={() => {
                  console.log();
                }}
                averageConsumption={+powerUsage.averagePowerInWatt.toFixed(2)}
                highestConsumption={+powerUsage.maxPowerInWatt.toFixed(2)}
                isFetching={
                  isFetchingPowerUsageStatisticsByYearMonth && isFirstTime
                }
              />
            </Col>
            <Col className="col-12 mt-4 mt-lg-0" lg={6}>
              <StaticInfoCard
                type="ENERGY_USAGE"
                spaceName={usageFilterStore.selectedSpace.name}
                selectedSmartDevice={usageFilterStore.selectedSmartDevice}
                date={new Date()}
                onMoreInfoClick={() => {
                  navigate(AppRoute.ENERGY_USAGE);
                }}
                todayUsage={+energyConsumption.consumedEnergyInUnits.toFixed(2)}
                dailyAverageUsage={
                  +energyConsumption.dailyAverageConsumedEnergyInUnits.toFixed(
                    2
                  )
                }
                currentMonthUsage={
                  +energyConsumption.monthlyConsumedEnergyInUnits.toFixed(2)
                }
                monthlyAverageUsage={
                  +energyConsumption.monthlyAverageConsumedEnergyInUnits.toFixed(
                    2
                  )
                }
                isFetching={
                  isFetchingEnergyConsumptionStatisticsByDate && isFirstTime
                }
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <UsageByTime
                isLoading={isFetchingViewExternalConsumptionDataPercentages}
                day={
                  externalConsumptionDataPercentages.find(
                    (ecdp) => ecdp.demandPeriod === EDemandPeriod.DAY
                  )?.percentage || 0
                }
                peak={
                  externalConsumptionDataPercentages.find(
                    (ecdp) => ecdp.demandPeriod === EDemandPeriod.PEAK
                  )?.percentage || 0
                }
                offPeak={
                  externalConsumptionDataPercentages.find(
                    (ecdp) => ecdp.demandPeriod === EDemandPeriod.OFF_PEAK
                  )?.percentage || 0
                }
                onArrowClick={() => {
                  navigate(AppRoute.METER_DASHBOARD);
                }}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <HighestPowerUsageDevices
                collapsed={collapsed}
                onClick={() => {
                  setCollapsed((ps) => !ps);
                }}
                devicesWithHighestPowerConsumption={
                  devicesWithHighestPowerConsumption
                }
                spaceName={usageFilterStore.selectedSpace.name}
                isFetching={
                  isFetchingDevicesWithHighestPowerConsumption &&
                  isDevicesWithHighestPowerConsumptionFirstTime
                }
                isLive={checkDeviceConnectionState(
                  usageFilterStore.selectedEntireSpace
                )}
              />
            </Col>
          </Row>
          <div className="container-white mt-3 position-relative">
            <Row className="justify-content-end">
              <Col className="col-auto">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    setShowFilter(true);
                    setIsEnergyConsumptionGraph(true);
                  }}
                  badgeValue={0}
                />
              </Col>
            </Row>
            <AreaChart
              borderColor1="#D84560"
              backgroundColor1="#CC292917"
              label1="Energy Consumption"
              yAxesUnit="kW"
              labels={energyConsumptionGraphLabels}
              data1={energyConsumptionGraphData}
              isChartDataAvailable={energyConsumptionGraphData.length > 0}
            />
            <SpinnerModal show={isFetchingEnergyConsumptionByDate} />
          </div>
        </>
      ) : (
        <>
          {!isFetchingSpaceCluster && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <AbnormalEnergyUsageModal
        show={showAbnormalEnergyUsageModal && isAbnormalPowerConsumers}
        onClose={() => setShowAbnormalEnergyUsageModal(false)}
        setUpdateCurrentState={
          setUpdateCurrentStateDevicesWithAbnormalPowerConsumption
        }
      />
      <SpinnerModal show={isFetchingSpaceCluster} />
    </div>
  );
};

export default Usage;
