import { useEffect, useState } from "react";
import { EDateTypes } from "../../../../../shared/oversight-core/enums/date-types";
import { EUnitType } from "../../../../../shared/oversight-core/enums/unit-type";
import FilteringSidePanel from "../../../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppDateRangeSelector from "../../../../../shared/oversight-core/ui-elements/app-date-range-selector/app-date-range-selector";

interface IProps {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  onFilter: (selectedDate: Date, selectedDateType: EDateTypes) => void;
  selectedDateType: EDateTypes;
  selectedDate: Date;
  onClearAll: () => void;
  selectedUnitType: EUnitType;
}

const SolarFilterSidePanel = (props: IProps) => {
  const { showFilter, setShowFilter, onFilter, onClearAll, selectedUnitType } =
    props;

  const [selectedDateType, setSelectedDateType] = useState(EDateTypes.DAILY);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    setSelectedDateType(props.selectedDateType);
  }, [props.selectedDateType]);

  useEffect(() => {
    setSelectedDate(new Date(props.selectedDate));
  }, [props.selectedDate]);

  return (
    <FilteringSidePanel
      isOpen={showFilter}
      onClose={() => {
        setShowFilter(false);
      }}
      onFilter={() => {
        onFilter(selectedDate, selectedDateType);
      }}
      onClearAll={onClearAll}
    >
      <>
        <div className="mt-4">
          <AppDateRangeSelector
            startDate={selectedDate}
            endDate={new Date()}
            setSelectedDate={(startDate: Date) => {
              setSelectedDate(startDate);
            }}
            dateTypes={
              selectedUnitType === EUnitType.ENERGY
                ? [[EDateTypes.DAILY, EDateTypes.MONTHLY, EDateTypes.YEARLY]]
                : []
            }
            selectedDateType={selectedDateType}
            setSelectedDateType={setSelectedDateType}
            maxDate={
              selectedDateType === EDateTypes.DAILY
                ? new Date()
                : new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  )
            }
          />
        </div>
      </>
    </FilteringSidePanel>
  );
};

export default SolarFilterSidePanel;
