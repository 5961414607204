import { Col, Row } from "react-bootstrap";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";

interface IProps {
  value: number;
  maxValue: number;
  icon: MtIcon;
}

const Gauge = (props: IProps) => {
  const { icon, value, maxValue } = props;

  const calculatePercentage = (currentValue: number, maxValue: number) => {
    if (maxValue === 0) return 0;
    return (currentValue / maxValue) * 100;
  };

  return (
    <div style={{ width: 200, height: 100 }}>
      <CircularProgressbarWithChildren
        value={calculatePercentage(value, maxValue)}
        styles={buildStyles({
          rotation: 0.75, // Rotate the circle to make it a half-circle
          pathColor: "#D08700", // Color for the gauge
          trailColor: "#F7F8EE", // Background trail color
          pathTransitionDuration: 0.5,
        })}
        circleRatio={0.5} // Makes it a half-circle
      >
        <Row className={"align-items-center justify-content-center mb-4"}>
          <Col xs={"auto"} className="pe-0">
            <MaterialIcon icon={icon} size={25} color="#D08700" />
          </Col>
          <Col xs={"auto"} className="ps-1">
            <label className="text-dark font-size-32 font-weight-500">
              {value}
            </label>
            <label className="text-light font-size-16 font-weight-40 ps-1">
              kW
            </label>
          </Col>
        </Row>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default Gauge;
