import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  data1: string[] | number[];
  data2?: string[] | number[];
  data3?: string[] | number[];
  dataLabel1?: string;
  dataLabel2?: string;
  dataLabel3?: string;
  borderColor1: string | string[];
  borderColor2?: string;
  borderColor3?: string;
  backgroundColor1: string | string[];
  backgroundColor2?: string;
  backgroundColor3?: string;
  yAxesUnit: string;
  labels: Array<string>;
  stacked?: boolean;
  hasSecondDataSet?: boolean;
  hasThirdDataSet?: boolean;
  borderRadius?: number;
  isDisplayLegend?: boolean;
  titleCallBack?: (tooltipItems: any) => string;
}

const BarChart = (props: IProps) => {
  const labels = props.labels;
  const options = {
    plugins: {
      legend: props.isDisplayLegend
        ? {
            display: true,
            position: "top" as const,
            align: "start" as const,
            labels: {
              usePointStyle: true,
              pointStyleWidth: 10,
              boxHeight: 7,
              font: {
                size: 14,
                family: "Poppins",
                weight: "normal" as const,
              },
            },
          }
        : { display: false },
      tooltip: {
        callbacks: {
          label: function (data: any) {
            return data.formattedValue + " " + props.yAxesUnit;
          },
          title: props.titleCallBack,
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: props.stacked,
      },
      y: {
        ticks: {
          callback: (value: any) => {
            return value + " " + props.yAxesUnit;
          },
        },
        stacked: props.stacked,
      },
    },
  };

  const data1 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
    ],
  };

  const data2 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
      {
        data: props.data2,
        backgroundColor: props.backgroundColor2,
        borderWidth: 1,
        borderColor: props.borderColor2,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel2 || "",
      },
    ],
  };

  const data3 = {
    labels: labels,
    datasets: [
      {
        data: props.data1,
        backgroundColor: props.backgroundColor1,
        borderWidth: 1,
        borderColor: props.borderColor1,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel1 || "",
      },
      {
        data: props.data2,
        backgroundColor: props.backgroundColor2,
        borderWidth: 1,
        borderColor: props.borderColor2,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel2 || "",
      },
      {
        data: props.data3,
        backgroundColor: props.backgroundColor3,
        borderWidth: 1,
        borderColor: props.borderColor3,
        borderRadius: props.borderRadius || 0,
        label: props.dataLabel3 || "",
      },
    ],
  };

  return (
    <Bar
      data={
        props.hasThirdDataSet ? data3 : props.hasSecondDataSet ? data2 : data1
      }
      options={options}
    />
  );
};

export default BarChart;
